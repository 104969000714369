.editor {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 16px;
    border-radius: 2px;
    margin-bottom: 2em;
    box-shadow: inset 0px 1px 8px -3px #ABABAB;
    background: #fefefe;
}

.editor :global(.public-DraftEditor-content) {
    min-height: 140px;
}

.editor, .draftJsMentionPlugin__mention__29BEd, .draftJsMentionPlugin__mention__29BEd:visited {
    color: #575f67;
    background: #303030;
}

.editor, .draftJsMentionPlugin__mention__29BEd:hover, .draftJsMentionPlugin__mention__29BEd:focus {
    color: #677584;
    background: #adb5bd;
}

.editor, .draftJsMentionPlugin__mention__29BEd:active {
    color: #222;
    background: #455261;
}

.editor, .draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm:active {
    background-color: #00bc8c;
}

.editor, .draftJsMentionPlugin__mentionSuggestionsEntryFocused__3LcTd {
    background-color: #3498DB;
}


.editor, .draftJsMentionPlugin__mentionSuggestions__2DWjA {
    border: 1px solid #303030;
    background: #303030;
    box-shadow: 0px 4px 10px 0px #455261;
}

