.vis-react {
    width: 65rem;
    height: 40rem;
}
.vis-react-title {
    text-align: center;
}

.vis-tooltip{
    position: absolute;
    visibility: hidden;
    padding: 5px;
    white-space: nowrap;
    font-family: verdana, serif;
    font-size: 10px;
    color: #000;
    background-color: #f5f4ed;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid #808074;
    box-shadow: 3px 3px 10px rgba(0,0,0,.2);
    pointer-events: none;
    z-index: 5;
}
